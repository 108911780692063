<template>
  <div class="allmsg" v-wechat-title="$route.meta.title">
    <navigation :noleft="true" msg="我的">
      <template>
        <div class="newsclass"><newses msg="black"></newses></div>
      </template>
    </navigation>

    <div class="topmsg">
      <div @click="tousermsg" class="imgcom">
        <div class="userimgclass">
          <img src="../../assets/userss.png" alt="" />
        </div>
        <div class="commsg">
          <div>
            <div>{{ company?company:'暂无认证信息' }}</div>
            <div class="xinxi">
              账号信息 <van-icon color="#FFFFFF" size=".33rem" name="arrow" />
            </div>
          </div>
          <div>{{ user.mobile }}</div>
        </div>
      </div>
    </div>

    <div class="yueclass">
      <div class="yueclass1">
        <div class="yumoney">
          <div>账户余额：</div>
          <div>{{ user.overall_balance }}</div>
        </div>
        <div @click="rechargeTo()">立即充值</div>
      </div>
      <div style="border-radius: 0.16rem;overflow:hidden;">
        <div class="yueclass2">
          <div @click="$router.push('/personDetail/myPost')" class="themenu">
            <div><img class="img1" src="../../assets/user_xing.png" alt="" /></div>
            <div>岗位管理</div>
          </div>
        <!-- <div @click="$router.push('./resumefavorites')" class="themenu">
          <div><img class="img1" src="../../assets/user_xing.png" alt="" /></div>
          <div>简历收藏</div>
        </div>
        <div @click="$router.push('./resumedownload')" class="themenu">
          <div><img class="img2" src="../../assets/user_down.png" alt="" /></div>
          <div>简历下载</div>
        </div> -->
        <div @click="$router.push('./profession')" class="themenu">
          <div><img class="img3" src="../../assets/user_qrcode.png" alt="" /></div>
          <div>二维码管理</div>
        </div>
      </div>
      <div v-if="user.status != 3" style="background: #ffffff;border-radius: 0 0 0.16rem 0.16rem;">
        <div class="yueclass3">
          <div class="renimg">
            <img src="../../assets/user_ren.png" alt="" />
            <div>进行企业认证可享受更多功能</div>
          </div>
          <div @click="$router.push('/enterprise')" class="liren">立即认证</div>
        </div>
      </div>
      </div>
      
    </div>

    <div class="bomsgclass">
      <div @click="$router.push('/profile')" class="dangan">
        <div>
          <div>职业档案</div>
          <van-icon color="#C0C4CC" size=".3rem" name="arrow" />
        </div>
        <div @click.stop="$router.push('/staffadd')" class="addyuanall">
          <div class="addyuan">
            <img src="../../assets/user_yuan.png" alt="" />
            <div>添加员工</div>
          </div>
        </div>

        <div class="ruzhi">
          <div @click.stop="toyuan(1)">未入职<span>{{count1}}</span></div>
          <div @click.stop="toyuan(2)">已入职<span>{{count2}}</span></div>
          <div @click.stop="toyuan(3)">已离职<span>{{count3}}</span></div>
        </div>
      </div>
      <img @click="toshareit" class="tuclass" src="../../assets/user_tu.png" alt="" />
      <div class="bomenuall">
        <div @click="$router.push('/record')" class="bomenu">
          <div>背调记录</div>
          <van-icon color="#C0C4CC" size=".32rem" name="arrow" />
        </div>
        <div @click="$router.push('/topupdetail')" class="bomenu">
          <div>账户明细</div>
          <van-icon color="#C0C4CC" size=".32rem" name="arrow" />
        </div>
        <div @click="$router.push('/details')" class="bomenu">
          <div>充值记录</div>
          <van-icon color="#C0C4CC" size=".32rem" name="arrow" />
        </div>
        <div @click="$router.push('./invoice')" class="bomenu">
          <div>我的发票</div>
          <van-icon color="#C0C4CC" size=".32rem" name="arrow" />
        </div>
        <div @click="$router.push('./nofeature')" class="bomenu">
          <div>企业商城</div>
          <van-icon color="#C0C4CC" size=".32rem" name="arrow" />
        </div>
        <div @click="$router.push('./setupall')" class="bomenu">
          <div>设置</div>
          <van-icon color="#C0C4CC" size=".32rem" name="arrow" />
        </div>
      </div>
    </div>
    
    <div style="height: 1.3rem"></div>
    <van-popup v-model="zhanghushow">
      <div class="quite1">确认退出登录吗？</div>
      <div class="quite2">
        <button @click="qu1meth" id="qu1">确认</button>
        <button @click="qu2meth" id="qu2">取消</button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import newses from "../../components/news/index";
import mskalert from "../mskalert/index.vue";
import paymeth from "../../../util/paymeth";
import certificationreminder from "../../components/certificationreminder/index";
export default {
  components: {
    mskalert,
    certificationreminder,
    newses,
  },
  data() {
    return {
      user: [],
      count1:0, // 未入职
      count2:0, // 已入职
      count3:0, // 已离职
      ismsk: false, //是否显示遮罩层弹窗
      msg: "",
      okmsg: "确定",
      nomsg: "取消",
      checked: "",
      company: "", // 公司名称
      zhanghushow: false,
      user:{status:3}
    };
  },
  methods: {
    tousermsg(){
      
      if(!(this.user.status== 3)){
        this.$router.push('/enterprise');
        return;
      }
      this.$router.push("/accountmsg");
    },
    toyuan(index){
      this.$router.push({
        path:"/profile",
        query:{
          zystatus:index
        }
      })
    },
    toshareit() {
      this.$router.push("/shareit");
    },
    rechargeTo() {
      this.$router.push("/recharge");
    },

    ok() {
      this.ismsk = false;
      this.$router.push("/enterprise");
    },
    no() {
      this.ismsk = false;
    },
    qu1meth() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      localStorage.removeItem("loginTime");
      this.$router.push("/login");
      this.zhanghushow = false;
    },
    qu2meth() {
      this.zhanghushow = false;
    },
    refuse() {
      this.zhanghushow = true;
    },
  },
  created() {
    let than = this;
    this.$http
      .post("/firm/v1/index/index", {
        reqType: "resume",
      })
      .then((res) => {
        res = JSON.parse(res.data);

        if (res.code == 0) {
          than.user = res.data;
        }
        // if (res.data.status == 1) {
        //
        //   this.ismsk = true;
        //   this.msg = "是否进行企业认证";
        // } else if (res.data.status == 4) {
        //
        //   this.ismsk = true;
        //   this.msg = "审核未通过，是否进行企业认证";
        // }
      });
    /**
     * 获取公司信息
     */
    this.$http
      .post("/firm/v1/userinfo/companyinfo", {
        reqType: "userinfo",
      })
      .then((res) => {
        localStorage.setItem("username", JSON.parse(res.data).data.username);
        this.company = JSON.parse(res.data).data.company;
        localStorage.setItem("enterprise", this.company);
      });

     /**
      * 获取入职，离职，未入职信息
      */
     this.$http.post("/firm/v1/userinfo/staffInfo",{
       "reqType": "userinfo",
     })
     .then(res=>{
       let msg = JSON.parse(res.data).data;
       this.count1 = msg.count1; // 未入职
       this.count2 = msg.count2; // 已入职
       this.count3 = msg.count3; // 已离职
     })
  },
};
</script>
<style scoped>
.bomenuall {
  background-color: #ffffff;
  padding: 0 0.34rem;
  border-radius: 0.16rem;
}
.bomenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.32rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
  padding: 0.24rem 0;
  border-bottom-color: #f6f6f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bomenu > :last-child {
  border-bottom: none;
}
.tuclass {
  width: 100%;
  margin-top: 0.05rem;
}
.addyuan {
  display: flex;
  align-items: center;
  background: #f6f6f7;
  border-radius: 0.72rem;
  padding: 0.18rem 0.4rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
}
.addyuan img {
  width: 0.36rem;
  height: 0.36rem;
  margin-right: 0.16rem;
}
.addyuanall {
  margin: 0.42rem 0;
  text-align: center;
  margin-left: 50%;
  transform: translateX(-50%);
}
.addyuanall {
  display: inline-block;
}
.dangan {
  background-color: #ffffff;
  padding: 0.26rem 0.28rem;
  border-radius: 0.16rem;
}
.dangan > :first-child {
  font-size: 0.32rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ruzhi {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
}
.ruzhi span{
  color: #909399;
  margin-left: .1rem;
}
.ruzhi > div {
  flex: 1;
  text-align: center;
}
.ruzhi > :nth-child(2) {
  border: 0.02rem solid #e5e5e5;
  border-top: none;
  border-bottom: none;
}
.bomsgclass {
  margin: 0.24rem 0.32rem;
}
.liren {
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.48rem;
  background: rgba(69, 113, 208, 0.9);
  border-radius: 1rem 1rem 1rem 1rem;
  padding: 0 0.26rem;
}
.yueclass3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem 0.24rem;
  border-radius: 0 0 0.16rem 0.16rem;
  background: linear-gradient(
    360deg,
    rgba(157, 188, 255, 0) 0%,
    rgba(56, 108, 222, 0.15) 100%
  );
}
.renimg {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #4c525f;
  line-height: 0.48rem;
}
.renimg img {
  width: 0.38rem;
  margin-right: 0.12rem;
}
.themenu {
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
}
.themenu > :first-child {
  text-align: center;
  margin-bottom: 0.08rem;
}
.themenu .img1 {
  width: 0.5rem;
}
.themenu .img2 {
  width: 0.36rem;
}
.themenu .img3 {
  width: 0.38rem;
}
.yueclass2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  padding: 0.3rem 0.56rem;
  border-radius: 0.16rem 0.16rem 0 0;
}
.topmsg {
  height: 2.76rem;
  background-color: antiquewhite;
  background-image: url("../../assets/user_back.png");
  background-size: 100% 100%;
  padding: 0.4rem 0.42rem;
}
.userimgclass {
  min-width: 1.16rem;
  max-width: 1.16rem;
  height: 1.16rem;
  overflow: hidden;
  border-radius: 50%;
}
.userimgclass img {
  width: 100%;
  height: 100%;
}
.imgcom {
  display: flex;
  align-items: center;
}
.commsg {
  margin-left: 0.18rem;
  width: 100%;
}
.commsg > :first-child {
  font-size: 0.28rem;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.commsg > :nth-child(2) {
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.48rem;
}
.xinxi {
  font-size: 0.24rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
}
.yueclass {
  margin: 0 0.32rem;
  margin-top: -1.68rem;
}
.yueclass1 {
  margin: 0 0.28rem;
  background: linear-gradient(180deg, #474542 0%, #1b1815 100%);
  border-radius: 0.3rem 0.3rem 0rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
}
.yueclass1 > :nth-child(2) {
  background: linear-gradient(360deg, #d2b083 0%, #efdabd 100%);
  border-radius: 1rem 1rem 1rem 1rem;
  padding: 0.04rem 0.34rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.4rem;
}
.yumoney {
  display: flex;
  align-items: center;
}
.yumoney > :first-child {
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.4rem;
}
.yumoney > :nth-child(2) {
  font-size: 0.28rem;
  font-weight: 400;
  color: #d2b083;
  line-height: 0.4rem;
}

/* <!-----------------------------------> */
.quite1 {
  font-size: 0.34rem;
  line-height: 0.68rem;
  text-align: center;
  margin-top: 0.62rem;
  margin-bottom: 0.41rem;
}
#qu1,
#qu2 {
  border: none;
  width: 50%;
  font-size: 0.32rem;
  line-height: 0.44rem;
  text-align: center;
  padding: 0.22rem 0;
  margin-bottom: 0;
}
#qu1 {
  background-color: #f65a16;
  color: #ffffff;
}
#qu2 {
  background-color: #dcdcdc;
  color: #000000;
}
.van-popup--center {
  width: 75%;
  border-radius: 0.2rem;
  overflow: hidden;
}
.tomsg {
  color: #141f33;
  font-size: 0.34rem;
  text-align: center;
  height: 0.88rem;
  font-weight: bold;
  line-height: 0.88rem;
  background-color: #ffffff;
  position: relative;
}

.allmsg {
  background-color: #f6f6f7;
  height: 100%;
  padding-bottom: 0.3rem;
}
.mybodyMsg {
  padding: 0.45rem 0.32rem;
  background-color: #ffffff;
}
.imageContact {
  display: flex;
  align-items: center;
}
.imageContact > div:first-child {
  width: 1.36rem;
  height: 1.36rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.19rem;
}
.imageContact div img {
  width: 100%;
  height: 100%;
}
.imageContact + p {
  color: #000000;
  margin-top: 0.31rem;
  margin-bottom: 0.16rem;
  font-size: 0.26rem;
  font-weight: bold;
}
.contact p {
  color: #000000;
}

.contact p + p {
  color: #828181;
}
.contact p:first-child {
  font-weight: bold;
  font-size: 0.3rem;
}
.contact p:nth-child(2) {
  font-size: 0.3rem;
  color: #828181;
}
.money {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.money button {
  background-color: #f68b1d;
  border: none;
  font-size: 0.24rem;
  color: #ffffff;
  width: 1.76rem;
  height: 0.56rem;
  border-radius: 5px;
}
.money p {
  font-size: 0.6rem;
  font-weight: bold;
}
.ctn {
  padding: 0.57rem 0.64rem;
  margin: 0.2rem 0.32rem;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}
.ctn > div {
  display: flex;
  align-items: center;
}
.im {
  width: 0.8rem;
  margin-right: 0.3rem;
}
.rz {
  width: 0.79rem;
}
.rz + p {
  font-weight: bold;
  color: #141f33;
  font-size: 0.3rem;
}
.renzheng p {
  color: #141f33;
  font-weight: bold;
}
.renzheng p:first-child {
  color: #b1b1b1;
  font-weight: normal;
  font-size: 0.2rem;
}
.renzheng p:nth-child(2) {
  font-size: 0.3rem;
  color: #141f33;
  font-weight: bold;
}
/* 
.mymenu div {
  padding: 0 0.38rem;
} */
.mymenu {
  padding: 0.1rem 0.3rem;
}
.mymenu div div {
  font-size: 0.3rem;
}
.mymenu div img {
  width: 0.22rem;
  margin-right: 0.18rem;
}
.mymenu div img:first-child {
  width: 0.4rem;
}
.mymenu div img:nth-child(2) {
  width: 0.14rem;
  margin-right: 0;
}
.popups {
  z-index: 1100;
  position: fixed;
  background-color: #ffffff;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 10px;
  overflow: hidden;
}
.popups p {
  margin: 0 0.69rem;
  min-width: 3.05rem;
  margin-bottom: 0.64rem;
  text-align: center;
  font-size: 0.3rem;
  color: #000000;
}
.popups > div:first-child {
  display: flex;
  justify-content: space-between;
  margin: 0.34rem 0.33rem 0.22rem 0;
}
.popups > div:first-child img {
  width: 0.34rem;
}
#btqone,
#btqtwo {
  width: 50%;
  margin: 0;
  border: none;
  padding: 0.19rem 0;
}
#btqone {
  background-color: #f65a16;
  color: #ffffff;
}
#btqtwo {
  background-color: #dcdcdc;
}
</style>
